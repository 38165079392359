<template>
  <div>
    <v-row class="d-flex ma-3 my-0">
      <v-col cols="4">
        <position-filter></position-filter>
      </v-col>
      <v-col cols="8">
        <ad-unit-filter></ad-unit-filter>
      </v-col>
    </v-row>
    <v-row class="d-flex ma-3 my-0">
      <v-col cols="4">
        <date-filter
          store="regie"
          storeGetter="getForecastDates"
          storeUpdater="updateForecastDates"
          periodType="future-only"
        ></date-filter>
      </v-col>
      <v-col cols="4">
        <cohort-filter></cohort-filter>
      </v-col>
      <v-col cols="2"></v-col>
      <v-col cols="2" class="d-flex">
        <v-spacer />
        <apply-filters :disabled="disabled" outlined color="secondary" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";

import useAxios from "@/hooks/useAxios";
import ApplyFilters from "@/components/common/filters/ApplyFilters.vue";
import DateFilter from "@/components/common/filters/DateFilter";
import AdUnitFilter from "@/components/regie/forecast/filters/AdUnitFilter";
import CohortFilter from "@/components/regie/forecast/filters/CohortFilter";
import PositionFilter from "@/components/regie/forecast/filters/PositionFilter";

export default {
  name: "Filters",
  components: {
    AdUnitFilter,
    ApplyFilters,
    CohortFilter,
    DateFilter,
    PositionFilter,
  },
  data() {
    return {
      disabled: true,
    };
  },
  setup() {
    const { isLoading } = useAxios();

    return {
      isLoading,
    };
  },
  created() {
    this.updateDisabled();
  },
  methods: {
    debouncedUpdateDisabled: debounce(async function () {
      this.updateDisabled();
    }, 500),
    updateDisabled() {
      this.disabled =
        this.isLoading ||
        !this.startDate ||
        !this.endDate ||
        (isEmpty(this.cohorts) &&
          isEmpty(this.positions) &&
          isEmpty(this.adUnits));
    },
  },
  computed: {
    startDate() {
      return this.$store.getters["regie/getForecastDates"][0];
    },
    endDate() {
      return this.$store.getters["regie/getForecastDates"][1];
    },
    cohorts() {
      return this.$store.getters["regie/getPermutiveCohorts"];
    },
    positions() {
      return this.$store.getters["regie/getGamPositions"];
    },
    adUnits() {
      return this.$store.getters["regie/getGamAdUnits"];
    },
  },
  watch: {
    isLoading() {
      this.debouncedUpdateDisabled();
    },
    startDate() {
      this.debouncedUpdateDisabled();
    },
    endDate() {
      this.debouncedUpdateDisabled();
    },
    cohorts() {
      this.debouncedUpdateDisabled();
    },
    positions() {
      this.debouncedUpdateDisabled();
    },
    adUnits() {
      this.debouncedUpdateDisabled();
    },
  },
};
</script>

<style></style>
