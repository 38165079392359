<template>
  <div>
    <v-autocomplete
      class="pt-2"
      v-model="storedPositions"
      :items="positions"
      item-text="name"
      item-value="id"
      :search-input.sync="searchText"
      v-on:keyup="debouncedGetData"
      clearable
      dense
      hide-details
      multiple
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="GAM Positions"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";

export default {
  name: "PositionFilter",
  data() {
    return {
      positions: [],
      searchText: "",
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { data } = await this.axiosGet("/gam/positions", {
        search_value: this.searchText,
      });

      this.positions = data;
    },
  },
  created() {
    this.getData();
  },
  computed: {
    storedPositions: {
      get() {
        return this.$store.getters["regie/getGamPositions"];
      },
      set(e) {
        this.$store.dispatch("regie/updateGamPositions", e);
      },
    },
  },
};
</script>

<style></style>
