<template>
  <v-img
    :src="logo"
    alt="logo régie"
    contain
    eager
    class="mx-auto"
    max-width="650"
  ></v-img>
</template>

<script>
import Logo from "@/assets/images/regie/logo.png";

export default {
  name: "Logo",
  data() {
    return {
      logo: Logo,
    };
  },
};
</script>

<style scoped></style>
