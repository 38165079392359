<template>
  <div>
    <v-autocomplete
      class="pt-2"
      v-model="storedCohorts"
      :items="cohorts"
      item-text="name"
      item-value="code"
      v-on:keyup="debouncedGetData"
      clearable
      dense
      hide-details
      multiple
      outlined
      :menu-props="{ maxHeight: '400', zIndex: 13 }"
      label="Cohortes Permutive"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import useAxios from "@/hooks/useAxios";

export default {
  name: "CohortFilter",
  data() {
    return {
      cohorts: [],
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      const { data } = await this.axiosGet("/regie/permutive_cohorts");

      this.cohorts = data;
    },
  },
  created() {
    this.getData();
  },
  computed: {
    storedCohorts: {
      get() {
        return this.$store.getters["regie/getPermutiveCohorts"];
      },
      set(e) {
        this.$store.dispatch("regie/updatePermutiveCohorts", e);
      },
    },
  },
};
</script>

<style></style>
