<template>
  <div>
    <logo></logo>
    <p class="text-2xl mb-6">Prévision inventaire</p>
    <v-card class="mb-6 pa-2">
      <filters></filters>
    </v-card>

    <v-card>
      <v-card-title>GAM Forecast</v-card-title>
      <v-card-text class="d-flex flex-wrap pb-4">
        <v-container v-if="loading" class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
        </v-container>
        <v-row v-else>
          <v-col
            cols="6"
            lg="2"
            md="4"
            v-for="(item, key) in forecastData"
            :key="key"
          >
            <div class="d-flex">
              <v-avatar size="44" color="primary" rounded class="elevation-1">
                <v-icon dark color="white" size="30">
                  {{ item.icon }}
                </v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0">{{ key }}</p>
                <v-tooltip max-width="350" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      class="text--primary text-xl font-weight-bold"
                      v-on="on"
                      v-bind="attrs"
                      >{{ item.value }}</span
                    >
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";

import Logo from "@/components/regie/Logo";
import useAxios from "@/hooks/useAxios";
import { formatNumber } from "@/utils/formatting";
import {
  // mdiGiftOutline,
  // mdiPackageVariant,
  mdiPackageVariantClosed,
  // mdiSafeSquareOutline,
  // mdiTruckDeliveryOutline,
} from "@mdi/js";

import Filters from "@/components/regie/forecast/filters/Filters";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "Forecast",
  components: {
    Filters,
    Logo,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {
      loading: false,
      forecastData: {
        availableUnits: {
          value: 0,
          icon: mdiPackageVariantClosed,
          description:
            "Le nombre d'unités publicitaires disponibles pour une impression d'annonce donnée. \
            Les unités publicitaires disponibles sont celles qui n'ont pas encore été vendues \
            ou attribuées à une impression d'annonce.",
        },
        /*
        deliveredUnits: {
          value: 0,
          icon: mdiTruckDeliveryOutline,
          description:
            "Le nombre d'unités publicitaires effectivement livrées pour une impression d'annonce donnée. \
            Les unités publicitaires livrées sont celles qui ont été vendues à un annonceur et \
            qui ont été diffusées sur un site Web ou une application.",
        },
        matchedUnits: {
          value: 0,
          icon: mdiPackageVariant,
          description:
            "Le nombre d'unités publicitaires qui ont été prises en compte \
          pour une impression d'annonce donnée. Les unités publicitaires prises en compte \
          sont celles qui ont été considérées comme éligibles pour être livrées \
          pour une impression d'annonce donnée.",
        },
        possibleUnits: {
          value: 0,
          icon: mdiGiftOutline,
          description:
            "Le nombre total d'unités publicitaires qui auraient pu être livrées \
          pour une impression d'annonce donnée. Les unités publicitaires possibles sont celles \
          qui n'ont pas été bloquées par des filtres ou des restrictions.",
        },
        reservedUnits: {
          value: 0,
          icon: mdiSafeSquareOutline,
          description:
            "Le nombre d'unités publicitaires réservées \
          pour une impression d'annonce donnée. Les unités publicitaires réservées sont celles \
          qui ont été vendues à un annonceur, mais qui n'ont pas encore été diffusées.",
        },
        */
      },
      previousRequestParams: {},
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (!this.applyFilters) {
        return;
      }
      const adUnitIdList = this.adUnits.join(",");
      const cohortIdList = this.cohorts.join(",");
      const positionList = this.positions.join(",");

      if (!cohortIdList && !adUnitIdList && !positionList) {
        return;
      }

      if (
        this.startDate &&
        this.startDate.length === 10 &&
        this.endDate &&
        this.endDate.length === 10
      ) {
        const params = {
          start_date: this.startDate,
          end_date: this.endDate,
          cohort_code_list: cohortIdList,
          position_list: positionList,
          ...(this.adUnits.length > 0
            ? { a_d_unit_id_list: adUnitIdList } //  to avoid AdBlock misadventures, don't name it `ad_unit_*`
            : {}),
        };

        if (isEqual(params, this.previousRequestParams)) {
          return;
        }

        this.loading = true;

        const { data } = await this.axiosGet("/regie/gam_forecast", params);
        this.previousRequestParams = params;

        for (const key of Object.keys(data)) {
          if (key in this.forecastData) {
            this.forecastData[key].value = formatNumber(data[key]);
          }
        }

        this.loading = false;

        // Force clicking on button again to re-run that request :
        // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
        this.$store.dispatch("common/updateApplyFilters", false);
      }
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    startDate() {
      return this.$store.getters["regie/getForecastDates"][0];
    },
    endDate() {
      return this.$store.getters["regie/getForecastDates"][1];
    },
    cohorts() {
      return this.$store.getters["regie/getPermutiveCohorts"];
    },
    adUnits() {
      return this.$store.getters["regie/getGamAdUnits"];
    },
    positions() {
      return this.$store.getters["regie/getGamPositions"];
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
  },
};
</script>

<style scoped></style>
